import { Button, message } from "antd";
import requestService from "api/request";
import { useFnLoading, useLoading } from "hooks/useLoading";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { LiveChatWidget, EventHandlerPayload } from "@livechat/widget-react";

const Register = () => {
  const [form, setForm] = useState(null);
  const isLoading = useLoading("REGISTER");
  const navigate = useNavigate();
  const { onLoading } = useFnLoading();
  const [chat, setChat] = useState();
  const onSubmit = async () => {
    if (form?.passWord !== form?.confirmPassWord)
      return message.warning("Xác nhận mật khẩu không chính xác");

    try {
      onLoading({
        type: "REGISTER",
        value: true,
      });
      await requestService.post("/auth/signup", {
        data: {
          name: form?.name,
          password: form?.passWord,
          phone: form?.phone,
          refCode: form?.refCode,
          roleCode: "USER",
        },
      });
      message.success("Đăng kí thành công");
      navigate("/login");
      setForm({
        phone: "",
        name: "",
        passWord: "",
        confirmPassWord: "",
        refCode: "",
      });
    } catch (error) {
      console.log(error);
      onLoading({
        type: "REGISTER",
        value: false,
      });

      message.error(error?.response?.data?.message || "Có lỗi xảy ra thử lại");

      setForm({
        phone: "",
        name: "",
        passWord: "",
        confirmPassWord: "",
        refCode: "",
      });
    }

    onLoading({
      type: "REGISTER",
      value: false,
    });
  };
  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }
  return (
    <div className="remix-auth-page bg-white">
      <LiveChatWidget
        license="18721344"
        visibility={chat?.visibility}
        onNewEvent={handleNewEvent}
        onVisibilityChanged={(data) => {
          setChat({
            availability: "online",
            visibility: data?.visibility,
          });
        }}
      />
      <div className="login-header">
        <div>
          <img
            alt=""
            className="logo-login"
            src="https://core-api.shhope247.click/assets/upload/setting/1690110201_1689641502-logo.png"
          />
          <h4>Đăng ký</h4>
        </div>
        <div>
          <div
            style={{ cursor: "pointer" }}
            className="help"
            onClick={() =>
              setChat({
                availability: "online",
                visibility: "maximized",
              })
            }
          >
            Bạn cần giúp đỡ?
          </div>
        </div>
      </div>
      <div
        className="auth-auth-container login-container register-container"
        style={{
          backgroundImage:
            "url('https://res.cloudinary.com/dnc07cnyn/image/upload/v1709047625/2024-02-27_21.36.34_yekfze.jpg')",
        }}
      >
        <div className="login-board register-board">
          <img
            className="logo"
            src="https://core-api.shhope247.click/assets/upload/setting/1690110208_1689641502-logo.png"
            alt=""
          />
          <h3>Đăng ký</h3>
          <div className="ant-form ant-form-vertical auth-form-user">
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <input
                      className="ant-input"
                      placeholder="Số điện thoại"
                      id="username"
                      type="text"
                      value={form?.phone}
                      onChange={(e) =>
                        setForm({ ...form, phone: e?.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <span className="ant-input-affix-wrapper ant-input-password">
                      <input
                        placeholder="Tên người dùng"
                        id="full_name"
                        type="text"
                        className="ant-input"
                        value={form?.name}
                        onChange={(e) =>
                          setForm({ ...form, name: e?.target.value })
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <span className="ant-input-affix-wrapper ant-input-password">
                      <input
                        placeholder="Mật khẩu"
                        id="password"
                        type="password"
                        className="ant-input"
                        value={form?.passWord}
                        onChange={(e) =>
                          setForm({ ...form, passWord: e?.target.value })
                        }
                      />
                      <span className="ant-input-suffix">
                        <span
                          role="img"
                          aria-label="eye-invisible"
                          className="anticon anticon-eye-invisible ant-input-password-icon"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="eye-invisible"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path>
                            <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <span className="ant-input-affix-wrapper ant-input-password">
                      <input
                        placeholder="Nhập Lại Mật Khẩu"
                        id="confirmed"
                        type="password"
                        className="ant-input"
                        value={form?.confirmPassWord}
                        onChange={(e) =>
                          setForm({ ...form, confirmPassWord: e?.target.value })
                        }
                      />
                      <span className="ant-input-suffix">
                        <span
                          role="img"
                          aria-label="eye-invisible"
                          className="anticon anticon-eye-invisible ant-input-password-icon"
                        >
                          <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="eye-invisible"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                          >
                            <path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 000-51.5zm-63.57-320.64L836 122.88a8 8 0 00-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 000 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 000 11.31L155.17 889a8 8 0 0011.31 0l712.15-712.12a8 8 0 000-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 00-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 01146.2-106.69L401.31 546.2A112 112 0 01396 512z"></path>
                            <path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 00227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 01-112 112z"></path>
                          </svg>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="ant-row ant-form-item">
              <div className="ant-col ant-form-item-control">
                <div className="ant-form-item-control-input">
                  <div className="ant-form-item-control-input-content">
                    <input
                      className="ant-input"
                      placeholder="Mã voucher"
                      id="ref_no"
                      type="text"
                      value={form?.refCode}
                      onChange={(e) =>
                        setForm({ ...form, refCode: e?.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <p>
              <Button
                loading={isLoading}
                disabled={isLoading}
                type="submit"
                className="ant-btn ant-btn-default btn-auth"
                onClick={onSubmit}
              >
                <span>Đăng ký</span>
              </Button>
            </p>
          </div>
          <div className="login-more">
            <h5>Hoặc</h5>
            <div className="login-account">
              <button type="button" className="btn-line">
                <img src="/assets/images/fb.png" alt="" />
                <span>Facebook</span>
              </button>
              <button type="button" className="btn-line">
                <img src="/assets/images/google.png" alt="" />
                <span>Google</span>
              </button>
            </div>
          </div>
          <div className="login_register">
            <span style={{ color: "black" }}>
              Bằng việc đăng kí, bạn đã đồng ý với Shopee về
            </span>
            <a
              className="btn-register"
              href="https://help.shopee.vn/portal/article/77243"
              target="_blank"
              rel="noreferrer"
            >
              Điều khoản dịch vụ
            </a>
            <span style={{ color: "black" }}> &amp;</span>
            <a
              className="btn-register"
              href="https://help.shopee.vn/portal/article/77244"
              target="_blank"
              rel="noreferrer"
            >
              Chính sách bảo mật.
            </a>
          </div>
          <div className="login_register">
            Bạn đã có tài khoản?
            <Link className="btn-register" to="/login">
              Đăng nhập
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
